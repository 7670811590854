import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authenticatedFetchData, API_HOST } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { CreateLoginLinkDialog } from './CreateLoginLinkDialog'
import { SendPasswordDialog } from './SetPasswordDialog'
import { MoneyStatisticsTable } from './MoneyStatisticsTable'
import { UserPayouts } from './UserPayouts'
import { UserPendingBags } from './UserPendingBags'
import { Notes } from '../../common/notes/Notes'
import { hasEditUserPermission } from '../../../services/permission.service'
import { UserConsumerSessions } from './UserConsumerSessions'
import { DateWithUTCTooltip } from '../../common'
import { UserPhoneNumber } from './common/UserPhoneNumber'
import { UserEmail } from './common/UserEmail'
import { UserScheduledDelete } from './common/UserScheduledDelete'
import { UserEvents } from './UserEvents'

export const UserInfoPage = () => {
  const { userId } = useParams()
  const [userInfo, setUserInfo] = useState<UserInfoType>()
  const [requestStatus, setRequestStatus] = useState<string>('loading')
  const [activeTab, setActiveTab] = useState<string>('consumerSessions')
  const [showLoginLinkDialog, setShowLoginLinkDialog] = useState(false)
  const [showPasswordDialog, setShowPasswordDialog] = useState(false)

  useEffect(() => {
    const f = authenticatedFetchData(
      `${API_HOST}/mytomra/v1.0/hotlinetool/usersearch/id?userid=${encodeURIComponent(userId || '')}`
    )

    f.run().then(
      (userInfo: UserInfoType) => {
        setRequestStatus('')
        setUserInfo(userInfo)
      },
      (error: Error) => {
        if (error.name !== 'AbortError') {
          setRequestStatus('failed')
          logError(new Error('Failed to fetch user info'), error)
        }
      }
    )

    return () => f.abort()
  }, [userId])

  if (requestStatus === 'failed') {
    return <div className="alert alert-danger centerAbsolute">Whoopsie! Failed to fetch user info.</div>
  }

  if (userInfo) {
    const { firstName, email, id, moneyStatistics, appContext, created } = userInfo

    return (
      <div className="p-lg">
        <h1 className="text-xl mb-md">User Information</h1>

        <div className="grid grid-cols-12 gap-x-md">
          <div className="mr-sm col-span-4">
            <div className="card p-lg mb-lg flex flex-col space-y-md">
              <dl>
                <dt id="name" className="text-storm">
                  Name
                </dt>
                <dd aria-labelledby="name">{firstName}</dd>
              </dl>

              <UserEmail userInfo={userInfo} />

              <dl>
                <dt id="userId" className="text-storm">
                  User ID
                </dt>
                <dd aria-labelledby="userId">{id}</dd>
              </dl>

              <dl>
                <dt id="created" className="text-storm">
                  User created
                </dt>
                <dd aria-labelledby="created">{<DateWithUTCTooltip date={new Date(created)} />}</dd>
              </dl>

              <dl>
                <dt id="appContext" className="text-storm">
                  App name
                </dt>
                <dd aria-labelledby="appContext">{appContext}</dd>
              </dl>

              <UserPhoneNumber userInfo={userInfo} />

              <UserScheduledDelete userInfo={userInfo} />

              {hasEditUserPermission() && (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-md items-start pt-lg">
                  <button className="btn" onClick={() => setShowLoginLinkDialog(true)}>
                    Create login link
                  </button>

                  <button className="btn" onClick={() => setShowPasswordDialog(true)}>
                    Set password
                  </button>
                </div>
              )}
            </div>

            {showLoginLinkDialog && (
              <CreateLoginLinkDialog
                userId={id}
                appContext={appContext}
                onClose={() => setShowLoginLinkDialog(false)}
              />
            )}

            {showPasswordDialog && (
              <SendPasswordDialog
                userId={id}
                email={email}
                appContext={appContext}
                onClose={() => setShowPasswordDialog(false)}
              />
            )}

            <Notes sourceId={userId!} />
          </div>

          <div className="col-span-8">
            <div className="tabs" role="tablist">
              <button
                role="tab"
                onClick={() => setActiveTab('consumerSessions')}
                className={activeTab === 'consumerSessions' ? 'tab active' : 'tab'}
              >
                Consumer sessions
              </button>
              <button
                role="tab"
                onClick={() => setActiveTab('payouts')}
                className={activeTab === 'payouts' ? 'tab active' : 'tab'}
              >
                Payouts
              </button>
              <button
                role="tab"
                onClick={() => setActiveTab('moneyStatistics')}
                className={activeTab === 'moneyStatistics' ? 'tab active' : 'tab'}
              >
                Money statistics
              </button>
              <button
                role="tab"
                onClick={() => setActiveTab('pendingBags')}
                className={activeTab === 'pendingBags' ? 'tab active' : 'tab'}
              >
                Pending bags
              </button>
              <button
                role="tab"
                onClick={() => setActiveTab('userEvents')}
                className={activeTab === 'userEvents' ? 'tab active' : 'tab'}
              >
                User events
              </button>
            </div>

            <div className="card mt-lg">
              {activeTab === 'consumerSessions' ? (
                <UserConsumerSessions />
              ) : activeTab === 'payouts' ? (
                <UserPayouts />
              ) : activeTab === 'pendingBags' ? (
                <UserPendingBags />
              ) : activeTab === 'moneyStatistics' ? (
                moneyStatistics.length > 0 ? (
                  moneyStatistics.map((statistics, i) => (
                    <MoneyStatisticsTable key={`st-${i}`} statistics={statistics} />
                  ))
                ) : (
                  <div className="p-md">This user has no statistics yet</div>
                )
              ) : activeTab === 'userEvents' ? (
                <UserEvents />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="centerAbsolute">
      <div className="loadingSpinner" aria-label="Please wait" />
    </div>
  )
}
