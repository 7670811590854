import React, { useEffect, useState } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { Link } from 'react-router-dom'
import { CharityActionListItemSkeleton } from '../common/CharityActionListItemSkeleton'
import { ActionListItem } from '../common/ActionListItem'
import { loadNewCharityActions } from '../../../../services/charity.service'
import { PendingChangesReviewButton } from '../common/pending-changes/PendingChangesReviewButton'
import { ArrowRightIcon, HeartIcon, QuestionMarkIcon } from '../../../icons'
import { schemeMap, availableAppContexts } from '../common/charity'

type CharityAction =
  | CharitySummaryType
  | CampaignSummaryType
  | CharitySummaryWithPendingChangesType
  | CampaignSummaryWithPendingChangesType

export const CharityActionList = () => {
  const [newActions, setNewActions] = useState<CharityAction[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [appContextFilter, setAppContextFilter] = useState(availableAppContexts)

  useEffect(() => {
    setIsLoading(true)
    const { runAll, abortAll } = loadNewCharityActions()

    runAll()
      .then(actions => {
        setNewActions(actions)
        setIsLoading(false)
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          logError(new Error('Unable to load charity actions'), error)
          setIsLoading(false)
        }
      })

    return () => abortAll()
  }, [])

  const filterByState = (action: CharityAction) => {
    if (Object.hasOwn(action, 'charitySummary')) {
      return appContextFilter.includes((action as CampaignSummaryType)?.charitySummary.appContext)
    }

    return appContextFilter.includes((action as CharitySummaryType).appContext)
  }

  const filterByStatus = (action: CharityAction) => {
    if (Object.hasOwn(action, 'charitySummary')) {
      return (action as CampaignSummaryType)?.charitySummary.status !== 'DISABLED'
    }

    return (action as CharitySummaryType).status !== 'DISABLED'
  }

  const toggleSort = (state: string) => {
    return () => {
      if (appContextFilter.includes(state)) {
        setAppContextFilter(prevState => prevState.filter(st => st !== state))
      } else {
        setAppContextFilter(prevState => [...prevState, state])
      }
    }
  }

  const filteredActions = newActions.filter(filterByState).filter(filterByStatus)

  return isLoading ? (
    <div className="card [&>*:nth-child(even)]:bg-grey-light [&>*:first-child]:pt-md [&>*:last-child]:pb-md">
      <CharityActionListItemSkeleton count={10} />
    </div>
  ) : newActions.length === 0 ? (
    <div className="flex flex-col items-center space-y-md">
      <div className="bg-white rounded w-10 h-10 flex items-center justify-center">
        <HeartIcon width="4rem" height="4rem" className="text-storm" />
      </div>
      <div>
        <h2 className="text-lg text-center">All done!</h2>
        <p className="text-center">No charities or campaigns to review.</p>
      </div>
    </div>
  ) : (
    <>
      <div className="flex items-end justify-between mt-md pb-md">
        <h2>Pending review</h2>

        <div className="flex space-x-md">
          {availableAppContexts.map(appContext => (
            <label key={`l-${appContext}`} className="flex space-x-xs">
              <input
                type="checkbox"
                checked={appContextFilter.includes(appContext)}
                value={appContext}
                onChange={toggleSort(appContext)}
              />
              <span className="select-none">{schemeMap[appContext]}</span>
            </label>
          ))}
        </div>
      </div>

      {filteredActions.length === 0 && (
        <div className="flex flex-col items-center space-y-md">
          <div className="bg-white rounded w-10 h-10 flex items-center justify-center">
            <QuestionMarkIcon width="4rem" height="4rem" className="text-storm" />
          </div>
          <div>
            <h2 className="text-lg text-center">Nothing to show here</h2>
            <p className="text-center">No charities or campaigns matches your filter.</p>
          </div>
        </div>
      )}

      <ul className="w-full card [&>*:nth-child(even)]:bg-grey-light [&>*:first-child]:pt-md [&>*:last-child]:pb-md">
        {filteredActions.map(item => {
          if (Object.hasOwn(item, 'pendingCharityCreatedAt')) {
            const pendingCharity = item as CharitySummaryWithPendingChangesType
            return (
              <ActionListItem
                key={`pendingCharity-${pendingCharity.id}`}
                type="charity"
                status="Changed"
                appContext={pendingCharity.appContext}
                label={pendingCharity.displayName || pendingCharity.organizationName}
                subLabel={pendingCharity.organizationName}
                createdAt={pendingCharity.pendingCharityCreatedAt as string}
                listItemAction={
                  <div className="flex space-x-sm items-center">
                    <PendingChangesReviewButton
                      charityId={pendingCharity.id}
                      onComplete={changesToMerge => {
                        if (changesToMerge) {
                          setNewActions(prevState => prevState.filter(item => item.id !== pendingCharity.id))
                        }
                      }}
                    />

                    <Link
                      to={`/charities/${pendingCharity.id}`}
                      aria-label={`View ${pendingCharity.displayName} details`}
                      className="pl-lg py-md"
                    >
                      <ArrowRightIcon width="1.5rem" height="1.5rem" />
                    </Link>
                  </div>
                }
              />
            )
          }

          if (Object.hasOwn(item, 'campaignRunState')) {
            const charity = item as CharitySummaryType
            return (
              <ActionListItem
                key={`charity-${charity.id}`}
                type="charity"
                status="New"
                appContext={charity.appContext}
                label={charity.displayName || charity.organizationName}
                subLabel={charity.organizationName}
                createdAt={charity.createdAt as string}
                listItemAction={
                  <Link
                    to={`/charities/${charity.id}`}
                    aria-label={`View ${charity.displayName} details`}
                    className="pl-lg py-md"
                  >
                    <ArrowRightIcon width="1.5rem" height="1.5rem" />
                  </Link>
                }
              />
            )
          }

          if (Object.hasOwn(item, 'pendingCampaignCreatedAt')) {
            const pendingCampaign = item as CampaignSummaryWithPendingChangesType
            return (
              <ActionListItem
                key={`pendingCampaign-${pendingCampaign.id}`}
                type="campaign"
                status="Changed"
                appContext={pendingCampaign.charitySummary.appContext}
                label={pendingCampaign.name}
                subLabel={pendingCampaign.charitySummary.displayName || pendingCampaign.charitySummary.organizationName}
                createdAt={pendingCampaign.pendingCampaignCreatedAt as string}
                listItemAction={
                  <div className="flex space-x-sm items-center">
                    <PendingChangesReviewButton
                      charityId={pendingCampaign.charitySummary.id}
                      campaignId={pendingCampaign.id}
                      onComplete={changesToMerge => {
                        if (changesToMerge) {
                          setNewActions(prevState => prevState.filter(item => item.id !== pendingCampaign.id))
                        }
                      }}
                    />

                    <Link
                      to={`/charities/${pendingCampaign.charitySummary.id}?campaignId=${pendingCampaign.id}`}
                      aria-label={`View ${pendingCampaign.name} details`}
                      className="pl-lg py-md"
                    >
                      <ArrowRightIcon width="1.5rem" height="1.5rem" />
                    </Link>
                  </div>
                }
              />
            )
          }

          const campaign = item as CampaignSummaryType
          return (
            <ActionListItem
              key={`campaign-${campaign.id}`}
              type="campaign"
              status="New"
              appContext={campaign.charitySummary.appContext}
              label={campaign.name}
              subLabel={campaign.charitySummary.displayName || campaign.charitySummary.organizationName}
              createdAt={campaign.createdAt as string}
              listItemAction={
                <Link
                  to={`/charities/${campaign.charitySummary.id}?campaignId=${campaign.id}`}
                  aria-label={`View ${campaign.name} details`}
                  className="pl-lg py-md"
                >
                  <ArrowRightIcon width="1.5rem" height="1.5rem" />
                </Link>
              }
            />
          )
        })}
      </ul>
    </>
  )
}
