import React, { ChangeEvent, FunctionComponent, useContext, useState } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { CharityReviewContext } from '../../../../../contexts'
import { ConfirmDialog } from '../../../../../common'
import { uploadCharityAttachment } from '../../../../../../services/charity.service'
import { attachmentTypeMap } from '../../../../../../lib/charity'

type Props = {
  charityId: string
  type: CharityFileAttachmentType['type']
}

export const UploadAttachment: FunctionComponent<Props> = ({ charityId, type }) => {
  const [status, setStatus] = useState<RequestStatusType>('idle')
  const [file, setFile] = useState<File | undefined>()
  const [showConfirmUploadDialog, setShowConfirmUploadDialog] = useState<boolean>(false)
  const { setAttachments } = useContext(CharityReviewContext)

  const onFileChange = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    const file = files && files.length > 0 ? files[0] : undefined
    setStatus('pending')
    setFile(file)
    setShowConfirmUploadDialog(true)
  }

  const cancel = () => {
    setShowConfirmUploadDialog(false)
    setFile(undefined)
    setStatus('idle')
  }

  const uploadFile = async () => {
    if (file) {
      try {
        const updatedAttachments = await uploadCharityAttachment(charityId, type, file)
        setAttachments(updatedAttachments)
        setStatus('idle')
      } catch (error: any) {
        setStatus('failure')
        logError(new Error(`Unable to upload ${type} file`), error)
      }
    } else {
      setStatus('idle')
    }
  }

  return (
    <>
      <div className="flex items-center bg-[rgba(var(--fog),0.5)] px-lg py-sm rounded-half">
        {status === 'pending' ? (
          <div className="loadingSpinner" />
        ) : (
          <input
            aria-label={`Upload ${type}`}
            id={type}
            type="file"
            accept=".pdf, image/png, image/jpeg"
            name="file"
            onChange={onFileChange}
          />
        )}
      </div>

      {status === 'failure' && <div className="alert alert-danger w-full">Upload failed</div>}

      {showConfirmUploadDialog && (
        <ConfirmDialog
          title={`Upload ${type}?`}
          confirmButtonText="Yes"
          cancelButtonText="Cancel"
          description={`This will replace the existing ${attachmentTypeMap[type as keyof typeof attachmentTypeMap]}`}
          onCancel={cancel}
          onConfirm={async () => {
            setShowConfirmUploadDialog(false)
            await uploadFile()
          }}
        />
      )}
    </>
  )
}
