import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API_HOST, authenticatedFetchData } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { DateWithUTCTooltip } from '../../common'

export const UserEvents = () => {
  const { userId } = useParams()

  const [requestStatus, setRequestStatus] = useState<string>('loading')
  const [userEvents, setUserEvents] = useState<UserEventType[]>([])

  useEffect(() => {
    const f = authenticatedFetchData(`${API_HOST}/mytomra/v1.0/hotlinetool/userevents?userid=${userId}`)

    f.run().then(
      (events: any) => {
        setRequestStatus('idle')
        setUserEvents(events)
      },
      (error: Error) => {
        if (error.name !== 'AbortError') {
          setRequestStatus('failed')
          logError(new Error('Failed to fetch user events'), error)
        }
      }
    )

    return () => f.abort()
  }, [userId])

  if (requestStatus === 'loading') {
    return (
      <div className="flex items-center justify-center p-md">
        <div className="loadingSpinner"></div>
      </div>
    )
  }

  if (requestStatus === 'failed') {
    return <div className="alert alert-danger">Something went wrong</div>
  }

  return userEvents.length === 0 ? (
    <div className="p-md">This user has no events yet.</div>
  ) : (
    <table className="table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Type</th>
          <th>Comment</th>
        </tr>
      </thead>
      <tbody>
        {userEvents.map((event: UserEventType, i) => (
          <tr key={`ue-${i}`}>
            <td>
              <DateWithUTCTooltip date={new Date(event.timestamp)} />
            </td>
            <td>{event.type}</td>
            <td>{event.comment}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
