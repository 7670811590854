import React, { FunctionComponent, useState } from 'react'
import { hasEditUserPermission } from '../../../../services/permission.service'
import { Dialog } from '../../../common/Dialog'
import { logError } from '@tomra/datadog-browser-logging'
import { API_HOST, authenticatedFetchData } from '../../../../lib'

type Props = {
  userInfo: UserInfoType
}

export const UserPhoneNumber: FunctionComponent<Props> = ({ userInfo }) => {
  const { id, email, appContext, phoneNumber } = userInfo

  const [status, setStatus] = useState<RequestStatusType>('idle')
  const [showResetPhoneNumberDialog, setShowResetPhoneNumberDialog] = useState(false)
  const [userPhoneNumber, setUserPhoneNumber] = useState<string | null>(phoneNumber)

  async function resetPhoneNumberForUser() {
    try {
      setStatus('pending')

      await authenticatedFetchData(`${API_HOST}/mytomra/v1.0/hotlinetool/reset-user-phone-number?userId=${id}`, {
        method: 'POST'
      }).run()

      setUserPhoneNumber(null)
      setStatus('success')
    } catch (error: any) {
      logError(new Error('Failed to reset phone number'), error)
      setStatus('failure')
    }
  }

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3 items-end gap-md">
        <dl className="col-span-2">
          <dt id="phoneNumber" className="text-storm">
            Phone number
          </dt>
          <dd aria-labelledby="phoneNumber">{userPhoneNumber || 'Not provided'}</dd>
        </dl>

        {hasEditUserPermission() && userPhoneNumber ? (
          <button
            className="btn text-sm"
            onClick={() => {
              setStatus('idle')
              setShowResetPhoneNumberDialog(true)
            }}
          >
            Reset
          </button>
        ) : null}
      </div>

      {showResetPhoneNumberDialog && (
        <Dialog
          ariaLabel="Reset phone number"
          onClose={() => {
            setShowResetPhoneNumberDialog(false)
          }}
        >
          <div className="card p-lg flex flex-col gap-y-md pt-xl min-w-30 max-w-30">
            <h1 className="text-xl">Reset phone number</h1>

            {status === 'success' ? (
              <>
                <p>
                  The phone number was reset for <b>{email}</b>.
                </p>

                <div className="alert alert-warning">
                  The user will have to log out and back in for the reset to take effect.
                </div>

                <div className="flex items-center justify-end space-x-md">
                  <button className="btn" onClick={() => setShowResetPhoneNumberDialog(false)}>
                    Close
                  </button>
                </div>
              </>
            ) : (
              <>
                <p>
                  This will reset the phone number for <b>{email}</b> user account in <b>{appContext}</b>.
                </p>
                <div className="alert alert-warning">
                  The user will have to log out and back in for the reset to take effect.
                </div>

                {status === 'failure' && <div className="text-red-dark">Something went wrong. Please try again.</div>}

                <div className="flex items-center justify-end space-x-md">
                  <button className="btn" onClick={() => setShowResetPhoneNumberDialog(false)}>
                    Cancel
                  </button>
                  <button className="btn btn-primary-danger" onClick={resetPhoneNumberForUser}>
                    {status === 'pending' ? <div className="loadingSpinner" /> : 'Reset phone number'}
                  </button>
                </div>
              </>
            )}
          </div>
        </Dialog>
      )}
    </>
  )
}
