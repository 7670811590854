import React, { useState } from 'react'
import { API_HOST, authenticatedFetchData } from '../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { Dialog } from '../../common/Dialog'
import { DateWithUTCTooltip } from '../../common'

type Props = {
  userId: string
  onClose: (status: RequestStatusType) => void
  scheduledDeleteAt: string | null
}

export const CancelScheduledDeleteDialog = ({ userId, onClose, scheduledDeleteAt }: Props) => {
  const [status, setStatus] = useState<RequestStatusType>('idle')
  const [responseMessage, setResponseMessage] = useState<String>('')

  const cancelScheduledDelete = async () => {
    try {
      setStatus('pending')
      await authenticatedFetchData(`${API_HOST}/mytomra/v1.0/hotlinetool/cancel-scheduled-delete?userId=${userId}`, {
        method: 'POST'
      }).run()
      setStatus('success')
    } catch (error: any) {
      setStatus('failure')
      setResponseMessage(error.message)
      logError(new Error('Failed to remove scheduled user delete'), error)
    }
  }

  return (
    <>
      <Dialog ariaLabel={'Cancel scheduled user delete'} onClose={() => onClose(status)}>
        <div className="card p-xl flex flex-col gap-y-lg max-w-35">
          <h1 className="text-xl">Cancel scheduled delete</h1>

          {status === 'idle' ? (
            <p>
              User is scheduled for deletion at <DateWithUTCTooltip date={new Date(scheduledDeleteAt as string)} />
            </p>
          ) : status === 'success' ? (
            <p>Great! Scheduled delete is removed.</p>
          ) : status === 'failure' ? (
            <>
              <p className="alert alert-danger">Whoopsie! Something went wrong. {responseMessage}</p>
            </>
          ) : null}

          {status !== 'success' && (
            <button className="btn btn-primary-dark self-end" onClick={cancelScheduledDelete}>
              {status === 'pending' ? <div className="loadingSpinner" /> : 'Remove scheduled delete'}
            </button>
          )}
        </div>
      </Dialog>
    </>
  )
}
