import React, { useState, useEffect, useCallback } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { authenticatedFetchData, API_HOST } from '../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { ArrowRightIcon } from '../icons'

export const UsersPage = () => {
  const { query } = useParams()
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState<string>(query ?? '')
  const [userList, setUserList] = useState<UserInfoType[] | null>(null)
  const [status, setStatus] = useState<RequestStatusType>('pending')

  const handleUserSearchResults = useCallback((userList: UserInfoType[]) => {
    setStatus('idle')
    setUserList(userList)
  }, [])

  const handleError = useCallback((error: Error) => {
    if (error.name !== 'AbortError') {
      setStatus('failure')
      logError(new Error('Failed to fetch user list'), error)
    }
  }, [])

  const searchUser = () => {
    setUserList(null)
    setStatus('pending')

    authenticatedFetchData(API_HOST + '/mytomra/v1.0/hotlinetool/usersearch?search=' + encodeURIComponent(searchTerm))
      .run()
      .then(handleUserSearchResults)
      .catch(handleError)
  }

  useEffect(() => {
    setUserList(null)
    setStatus('pending')

    const f = authenticatedFetchData(
      API_HOST + '/mytomra/v1.0/hotlinetool/usersearch?search=' + encodeURIComponent(query || '')
    )
    f.run().then(handleUserSearchResults).catch(handleError)

    return () => f.abort()
  }, [query, handleError, handleUserSearchResults])

  const isSearching = status === 'pending'

  const userSearchForm = (
    <form
      className="w-1/2"
      onSubmit={e => {
        e.preventDefault()
        searchUser()
      }}
    >
      <label htmlFor="search" className="label mb-xs">
        Search
      </label>
      <div className="grid grid-cols-8 gap-x-md">
        <input
          id="search"
          type="search"
          className="col-span-6"
          value={searchTerm}
          disabled={isSearching}
          onChange={({ target }) => setSearchTerm(target.value)}
        />
        <button
          type="submit"
          className="btn btn-primary-dark flex justify-center items-center col-span-2"
          disabled={isSearching}
        >
          {isSearching ? <div className="loadingSpinner" /> : 'Search'}
        </button>
      </div>
    </form>
  )

  if (status === 'failure') {
    return (
      <div>
        <h1 className="sr-only">Users</h1>
        <div className="alert alert-danger centerAbsolute">Whoopsie! Failed to fetch users. Please try again.</div>
      </div>
    )
  }

  const hasResults = userList && userList.length > 0
  const noResults = userList && userList.length === 0

  return (
    <div className="p-lg">
      <h1 className="text-xl mb-md">Users</h1>

      {hasResults ? (
        <>
          {userSearchForm}

          <div className="card mt-md">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>User ID</th>
                  <th>App name</th>
                  <th>Phone number</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {userList?.map(({ id, firstName, email, appContext, phoneNumber }) => (
                  <tr
                    className="cursor-pointer"
                    data-testid={`u-${id}`}
                    key={`u-${id}`}
                    onClick={() => navigate(`/user/${id}`)}
                  >
                    <td>{firstName}</td>
                    <td>{email}</td>
                    <td>{id}</td>
                    <td>{appContext}</td>
                    <td>{phoneNumber}</td>
                    <td>
                      <div className="flex justify-end">
                        <Link to={`/user/${id}`} aria-label={`See details for ${firstName}`}>
                          <ArrowRightIcon width="1rem" height="1rem" />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="flex flex-col">
          {userSearchForm}

          {noResults && (
            <>
              <p className="mt-md font-bold">Sorry, we couldn&apos;t find that user.</p>
              <p>Try to include the entire email or first name and last name in the search.</p>
            </>
          )}

          {isSearching && (
            <div className="flex justify-center mt-md">
              <div className="loadingSpinner" aria-label="Please wait" />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
