import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { API_HOST, authenticatedFetchData } from '../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { EditableData, FormField, ConfirmDialog } from '../../../../common'
import { CharityReviewContext } from '../../../../contexts'
import { hasCharityEditPermission } from '../../../../../services/permission.service'
import { HasPendingChangesBanner } from '../pending-changes/HasPendingChangesBanner'

type Props = {
  charityId: string
  campaignId: string
  campaignChanged: (campaign: CharityCampaignType) => void
}

export const CampaignReview: FunctionComponent<Props> = ({ charityId, campaignId, campaignChanged }) => {
  const { charity } = useContext(CharityReviewContext)
  const [status, setStatus] = useState<RequestStatusType>('idle')
  const [campaign, setCampaign] = useState<CharityCampaignType>()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [showConfirmStopDialog, setShowConfirmStopDialog] = useState<boolean>(false)
  const [showConfirmActivateDialog, setShowConfirmActivateDialog] = useState<boolean>(false)

  const canDisableCampaign = campaign?.status !== 'STOPPED' && charity.status !== 'NEW'
  const canApproveCampaign = charity.status === 'ACTIVE'
  const disableAllButtons = status === 'pending'

  const updateCampaign = (updatedCampaign: any) => {
    setStatus('pending')
    setError('')

    authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charityId}/campaign/${campaignId}`, {
      method: 'PUT',
      body: JSON.stringify(updatedCampaign)
    })
      .run()
      .then(response => {
        setStatus('idle')
        setCampaign(response)
        setIsEditMode(false)
        campaignChanged(response)
      })
      .catch((error: any) => {
        logError(new Error('Failed to update campaign'), error)
        setStatus('failure')
        setError(error.body.detail ? error.body.detail : 'Failed to update campaign')
      })
  }

  useEffect(() => {
    setStatus('pending')
    setError('')

    const { run, abort } = authenticatedFetchData(
      `${API_HOST}/charities/admin/v1/charities/${charityId}/campaign/${campaignId}`
    )

    run()
      .then(response => {
        setStatus('idle')
        setCampaign(response)
      })
      .catch((error: any) => {
        if (error.name !== 'AbortError') {
          logError(new Error('Failed to load campaign'), error)
          setStatus('failure')
          setError('Failed to load campaign')
        }
      })

    return () => abort()
  }, [charityId, campaignId])

  const updateCampaignStatus = (status: 'activate' | 'stop') => {
    setShowConfirmStopDialog(false)
    setShowConfirmActivateDialog(false)
    setStatus('pending')

    authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charityId}/campaign/${campaignId}/${status}`, {
      method: 'PUT'
    })
      .run()
      .then(response => {
        setStatus('idle')
        setCampaign(response)
        campaignChanged(response)
      })
      .catch((error: any) => {
        logError(new Error(`Failed to ${status} campaign`), error)
        setStatus('failure')
        setError(`Failed to ${status} campaign`)
      })
  }

  if (!campaign && error) {
    return <div className="alert alert-danger">{error}</div>
  }

  if (campaign) {
    const campaignFormFields: FormField[] = [
      { label: 'Name', name: 'name', required: true, maxLength: 60 },
      { label: 'Description', type: 'textarea', name: 'description', required: true, maxLength: 500 },
      { label: 'Start time', name: 'startTime', required: true, type: 'date' },
      { label: 'End time', name: 'endTime', required: true, type: 'date' },
      { label: 'Financial target', name: 'financialTarget' },
      { label: 'Engagement plan', type: 'textarea', name: 'engagementPlan', maxLength: 1000 },
      { label: 'Facebook', name: 'facebookHandle' },
      { label: 'Instagram', name: 'instagramHandle' },
      { label: 'LinkedIn', name: 'linkedinHandle' },
      { label: 'SnapChat', name: 'snapchatHandle' },
      { label: 'TikTok', name: 'tiktokHandle' },
      { label: 'YouTube', name: 'youtubeHandle' }
    ]

    return (
      <>
        <HasPendingChangesBanner
          charityId={charityId}
          campaignId={campaignId}
          mergeChanges={changesToMerge => {
            setCampaign({ ...campaign, ...(changesToMerge as CharityCampaignType) })
          }}
        />

        <EditableData
          isEditMode={isEditMode}
          formFields={campaignFormFields}
          initialValues={campaign}
          displayValueMap={{}}
          onUpdate={updateCampaign}
          isUpdating={status === 'pending'}
          onEdit={() => setIsEditMode(true)}
          editCancelled={() => {
            setIsEditMode(false)
            setError('')
          }}
        />

        {error && <div className="alert alert-danger mt-md">{error}</div>}

        {!isEditMode && hasCharityEditPermission() && (
          <>
            {!canApproveCampaign && (
              <p className="text-right mb-xs">Charity must be approved before approving campaign</p>
            )}

            <div className="flex justify-end">
              {campaign.status !== 'EXPIRED' && (
                <button
                  className="btn btn-primary-danger"
                  aria-label={`Stop ${campaign.name} campaign`}
                  onClick={() => setShowConfirmStopDialog(true)}
                  disabled={!canDisableCampaign || disableAllButtons}
                >
                  Stop campaign
                </button>
              )}

              {campaign.status === 'NEW' && (
                <button
                  className="btn btn-primary-dark ml-lg"
                  aria-label={`Approve ${campaign.name} campaign`}
                  onClick={() => setShowConfirmActivateDialog(true)}
                  disabled={!canApproveCampaign || disableAllButtons}
                >
                  Approve campaign
                </button>
              )}
            </div>
          </>
        )}

        {showConfirmStopDialog && (
          <ConfirmDialog
            title="Stop campaign?"
            description="The campaign will not appear in the app."
            confirmButtonText="Stop"
            cancelButtonText="Cancel"
            onCancel={() => setShowConfirmStopDialog(false)}
            onConfirm={() => updateCampaignStatus('stop')}
          />
        )}

        {showConfirmActivateDialog && (
          <ConfirmDialog
            title="Approve campaign?"
            description="The campaign will appear in the app."
            confirmButtonText="Activate"
            cancelButtonText="Cancel"
            onCancel={() => setShowConfirmActivateDialog(false)}
            onConfirm={() => updateCampaignStatus('activate')}
          />
        )}
      </>
    )
  }

  return (
    <div className="flex justify-center">
      <div className="loadingSpinner" />
    </div>
  )
}
