import React, { FunctionComponent, useState } from 'react'
import { hasCharityEditPermission } from '../../../../../../services/permission.service'
import { UploadAttachment } from './UploadAttachment'
import { ViewAttachment } from './ViewAttachment'
import { CheckmarkIcon } from '../../../../../icons'
import { attachmentTypeMap } from '../../../../../../lib/charity'

const icon = <CheckmarkIcon width="1.5rem" height="1.5rem" color="var(--colors-black)" />
const approvedIcon = <CheckmarkIcon width="1.5rem" height="1.5rem" color="var(--colors-green)" />

type Props = {
  charityId: string
  attachments: CharityFileAttachmentType[]
}

export const AttachmentList: FunctionComponent<Props> = ({ charityId, attachments }) => {
  const [reviewed, setReviewed] = useState<CharityFileAttachmentType['type'][]>([])
  const [previewingAttachment, setPreviewingAttachment] = useState<CharityFileAttachmentType['type'] | undefined>(
    undefined
  )

  const onViewed = (type: CharityFileAttachmentType['type']) => {
    const isReviewed = reviewed.indexOf(type) !== -1

    if (!isReviewed) {
      const newState = [...reviewed, type]
      setReviewed(newState)
    }
  }

  return (
    <>
      {attachments.map(({ type }) => {
        const typeReviewed = reviewed.indexOf(type) !== -1

        const attachment = attachments.find(a => a.type === type)

        if (!attachment || type === 'OrganizationLogo') {
          return null
        }

        return (
          <React.Fragment key={`attachment-${type}`}>
            <div className="grid grid-cols-[repeat(24,minmax(0,1fr))]">
              <div className="col-span-1 flex items-center">
                {!attachment ? null : typeReviewed ? approvedIcon : icon}
              </div>

              <div className="col-[2_/_span_12]">
                <button
                  className="font-bold link"
                  onClick={() => setPreviewingAttachment(prev => (prev === type ? undefined : type))}
                >
                  {attachmentTypeMap[type as keyof typeof attachmentTypeMap]}
                </button>

                <div className="text-sm">
                  Uploaded {new Date(attachment.savedAt).toLocaleString()} by {attachment.savedByEmail}
                </div>
              </div>

              {hasCharityEditPermission() && (
                <div className="col-[14_/_span_12] flex flex-col items-center justify-end">
                  <UploadAttachment charityId={charityId} type={type} />
                </div>
              )}
            </div>

            {attachment && previewingAttachment === type && (
              <ViewAttachment
                charityId={charityId}
                contentType={attachment.contentType}
                type={previewingAttachment}
                onViewed={onViewed}
              />
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}
