import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { OrganizationSection } from './charity-review/sections/OrganizationSection'
import { AccordionPanel } from '../../../common/accordion'
import { CharityReviewContext } from '../../../contexts'
import { BankingDataSection } from './charity-review/sections/BankingData'
import { ContactsSection } from './charity-review/sections/ContactsSection'
import { CharityApplicationSummary } from './charity-review/CharityApplicationSummary'
import { API_HOST, authenticatedFetchData } from '../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { CharityCampaigns } from './campaign-review/CharityCampaigns'
import { Notes } from '../../../common/notes/Notes'
import { Status } from '../../../common/styled/Status'
import { HasPendingChangesBanner } from './pending-changes/HasPendingChangesBanner'
import { SchemeAppLogo } from '../common/SchemeAppLogo'
import { CharityAttachments } from './charity-review/attachments/CharityAttachments'

export const CharityReviewPage: FunctionComponent = () => {
  const { charityId } = useParams() as { charityId: string }
  const [fetchCharityStatus, setFetchCharityStatus] = useState<RequestStatusType>('idle')
  const { charity, setCharity, setApprovedSections, sections } = useContext(CharityReviewContext)

  useEffect(() => {
    setFetchCharityStatus('pending')

    const { run, abort } = authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charityId}`)

    run()
      .then((charity: CharityType) => {
        setCharity(charity)
        setFetchCharityStatus('idle')

        if (charity.status === 'ACTIVE') {
          setApprovedSections(sections)
        }
      })
      .catch((error: any) => {
        if (error.name !== 'AbortError') {
          logError(new Error('Failed to load charity'), error)
          setFetchCharityStatus('failure')
        }
      })

    return () => abort()
  }, [charityId, sections, setApprovedSections, setCharity])

  return fetchCharityStatus === 'pending' ? (
    <div className="centerAbsolute">
      <div className="loadingSpinner" />
    </div>
  ) : fetchCharityStatus === 'failure' ? (
    <div>
      <h1 className="sr-only">Charity Review</h1>
      <div className="alert alert-danger">Failed to load charity</div>
    </div>
  ) : (
    <div className="py-lg grid grid-cols-12 gap-md">
      <div className="col-span-6 flex items-center space-x-md">
        <div className="w-[3.3rem] h-[3.3rem] rounded overflow-hidden flex items-center justify-center ring-2 ring-fog bg-white">
          <SchemeAppLogo appContext={charity.organization.appContext} />
        </div>

        <div>
          <div className="text-sm font-bold text-storm">{charity.organization.name}</div>
          <h1 className="text-xl">{charity.organization.displayName}</h1>
        </div>
      </div>
      <div className="col-span-2 self-end text-right text-xl mr-md">
        <Status status={charity.status} />
      </div>

      <div className="col-span-8">
        <HasPendingChangesBanner
          charityId={charityId}
          mergeChanges={changesToMerge => {
            setCharity({ ...charity, ...(changesToMerge as CharityType) })
          }}
        />

        <AccordionPanel title={sections[0]}>
          <OrganizationSection charityId={charityId} />
        </AccordionPanel>
        <AccordionPanel title={sections[1]}>
          <BankingDataSection charityId={charityId} />
        </AccordionPanel>
        <AccordionPanel title={sections[2]}>
          <ContactsSection />
        </AccordionPanel>
        <AccordionPanel title="Attachments">
          <CharityAttachments />
        </AccordionPanel>

        <CharityCampaigns charityId={charityId} />
      </div>

      <div className="col-span-4">
        <CharityApplicationSummary />

        <h2 className="font-bold text-sm text-storm mb-xs ml-lg">Notes</h2>
        <Notes sourceId={charityId} />
      </div>
    </div>
  )
}
