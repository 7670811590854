import React, { FunctionComponent, useState } from 'react'
import { hasEditUserPermission } from '../../../../services/permission.service'
import { UpdateEmailDialog } from '../UpdateEmailDialog'

type Props = {
  userInfo: UserInfoType
}

export const UserEmail: FunctionComponent<Props> = ({ userInfo }) => {
  const { id, email } = userInfo

  const [showEmailUpdateDialog, setShowEmailUpdateDialog] = useState(false)

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 items-end gap-md">
      <dl className="col-span-2">
        <dt id="email" className="text-storm">
          Email
        </dt>
        <dd className="break-all" aria-labelledby="email">
          {email}
        </dd>
      </dl>

      {hasEditUserPermission() && (
        <>
          <button className="btn text-sm" onClick={() => setShowEmailUpdateDialog(true)}>
            Update
          </button>

          {showEmailUpdateDialog && (
            <UpdateEmailDialog
              userId={id}
              onClose={() => {
                setShowEmailUpdateDialog(false)
              }}
            />
          )}
        </>
      )}
    </div>
  )
}
