import React, { FunctionComponent } from 'react'
import { charityValueMap } from '../../charity'
import { ArrowRightIcon } from '../../../../../icons'
import { CharityLogo } from '../../../charities-review/charity-review/CharityLogo'

export const DiffRow: FunctionComponent<{ fieldName: string; oldValue: any; newValue: any }> = ({
  fieldName,
  oldValue,
  newValue
}) => {
  const fieldIsDate =
    fieldName === charityValueMap['establishedDate'] ||
    fieldName === charityValueMap['startTime'] ||
    fieldName === charityValueMap['endTime']

  const fieldIsLogo = fieldName === charityValueMap['logoUrl']

  if (fieldIsLogo) {
    return (
      <div className="grid grid-cols-12 items-start space-x-sm py-md">
        <h2 className="col-span-2 text-storm mb-xs">{fieldName}</h2>
        <div className="col-span-4 justify-self-start">
          <CharityLogo url={oldValue} size="small" />
        </div>
        <div className="col-span-1 self-center">
          <ArrowRightIcon width="1rem" height="1rem" />
        </div>
        <div className="col-span-2 justify-self-start">
          <CharityLogo url={newValue} size="small" />
        </div>
      </div>
    )
  }

  return (
    <div className="grid grid-cols-12 items-start space-x-sm py-md">
      <h2 className="col-span-2 text-storm mb-xs">{fieldName}</h2>
      <div className="line-through col-span-4">
        {fieldIsDate ? new Date(oldValue).toLocaleDateString() : oldValue || 'No value'}
      </div>
      <div className="col-span-1">
        <ArrowRightIcon width="1rem" height="1rem" />
      </div>
      <div className="col-span-2">{fieldIsDate ? new Date(newValue).toLocaleDateString() : newValue || 'No value'}</div>
    </div>
  )
}
