import React, { createContext, Dispatch, FunctionComponent, ReactNode, SetStateAction, useState } from 'react'

const initialCharity: CharityType = {
  id: '',
  status: 'NEW',
  organization: {
    name: '',
    organizationAbn: '',
    appContext: '',
    registeredState: '',
    establishedDate: '',
    legalStructure: '',
    legalStructureDetails: '',
    geoFootprint: '',
    incorporationNumber: '',
    displayName: '',
    localGroupName: '',
    streetAddress: '',
    postCode: '',
    suburb: '',
    benefit: '',
    websiteUrl: '',
    description: '',
    externalId: null
  },
  bankingData: {
    accountName: '',
    accountBsb: '',
    accountNumber: '',
    dgrStatus: ''
  },
  primaryContact: {
    firstName: '',
    lastName: '',
    position: '',
    email: '',
    phone: ''
  },
  secondaryContact: {
    firstName: '',
    lastName: '',
    position: '',
    email: '',
    phone: ''
  },
  createdAt: '',
  administratorUserId: '',
  administratorEmail: '',
  updatedAt: '',
  updatedBy: '',
  logoUrl: '',
  barcode: ''
}

const initialSections = ['Organisation', 'Banking data', 'Contact information']

type ApplicationReview = {
  sections: string[]
  setSections: (sections: string[]) => void
  charity: CharityType
  setCharity: (application: CharityType) => void
  approvedSections: string[]
  approveSection: (section: string) => void
  setApprovedSections: (sections: string[]) => void
  allSectionsApproved: boolean
  editingSection: string
  setEditingSection: (section: string) => void
  withdrawSectionApproval: (section: string) => void
  attachments: CharityFileAttachmentType[]
  setAttachments: (attachments: CharityFileAttachmentType[]) => void
  pendingChanges: CharityType | null
  setPendingChanges: Dispatch<SetStateAction<CharityType | null>>
  pendingAttachments: CharityFileAttachmentType[]
  setPendingAttachments: (attachments: CharityFileAttachmentType[]) => void
}

export const CharityReviewContext = createContext<ApplicationReview>({
  sections: [],
  setSections: () => {},
  charity: initialCharity,
  setCharity: () => {},
  approvedSections: [],
  approveSection: () => {},
  setApprovedSections: () => {},
  allSectionsApproved: false,
  editingSection: '',
  setEditingSection: () => {},
  withdrawSectionApproval: () => {},
  attachments: [],
  setAttachments: () => {},
  pendingChanges: initialCharity,
  setPendingChanges: () => {},
  pendingAttachments: [],
  setPendingAttachments: () => {}
})

type Props = {
  children?: ReactNode
}

export const CharityReviewProvider: FunctionComponent<Props> = ({ children }) => {
  const [sections, setSections] = useState<string[]>(initialSections)
  const [charity, setCharity] = useState<CharityType>(initialCharity)
  const [approvedSections, setApprovedSections] = useState<string[]>(['Contact information'])
  const [editingSection, setEditingSection] = useState<string>('')
  const [attachments, setAttachments] = useState<CharityFileAttachmentType[]>([])
  const [pendingAttachments, setPendingAttachments] = useState<CharityFileAttachmentType[]>([])
  const [pendingChanges, setPendingChanges] = useState<CharityType | null>(null)

  const allSectionsApproved = approvedSections.length > 0 && approvedSections.length === sections.length

  const approveSection = (section: string) => {
    setApprovedSections([...approvedSections, section])
    setEditingSection('')
  }

  const withdrawSectionApproval = (section: string) => {
    const copy = [...approvedSections]
    const index = approvedSections.indexOf(section)
    copy.splice(index, 1)
    setApprovedSections(copy)
  }

  return (
    <CharityReviewContext.Provider
      value={{
        sections,
        setSections,
        charity,
        setCharity,
        approvedSections,
        approveSection,
        allSectionsApproved,
        setApprovedSections,
        editingSection,
        setEditingSection,
        withdrawSectionApproval,
        attachments,
        setAttachments,
        pendingChanges,
        setPendingChanges,
        pendingAttachments,
        setPendingAttachments
      }}
    >
      {children}
    </CharityReviewContext.Provider>
  )
}
