import React, { FunctionComponent, useState } from 'react'
import { DateWithUTCTooltip } from '../../../common'
import { hasEditUserPermission } from '../../../../services/permission.service'
import { CancelScheduledDeleteDialog } from '../CancelScheduledDeleteDialog'

type Props = {
  userInfo: UserInfoType
}

export const UserScheduledDelete: FunctionComponent<Props> = ({ userInfo }) => {
  const { id, scheduledDeleteAt } = userInfo

  const [showCancelScheduledDeleteDialog, setShowCancelScheduledDeleteDialog] = useState(false)
  const [userScheduledDeleteAt, setUserScheduledDeleteAt] = useState<string | null>(scheduledDeleteAt)

  return (
    <>
      <div className="flex justify-between items-end">
        <dl>
          <dt id="scheduledDelete" className="text-storm">
            Scheduled Delete
          </dt>
          <dd aria-labelledby="scheduledDelete">
            {userScheduledDeleteAt ? <DateWithUTCTooltip date={new Date(userScheduledDeleteAt)} /> : '-'}
          </dd>
        </dl>

        {userScheduledDeleteAt && hasEditUserPermission() && (
          <>
            <button className="btn text-sm" onClick={() => setShowCancelScheduledDeleteDialog(true)}>
              Cancel
            </button>

            {showCancelScheduledDeleteDialog && (
              <CancelScheduledDeleteDialog
                userId={id}
                scheduledDeleteAt={userScheduledDeleteAt}
                onClose={status => {
                  setShowCancelScheduledDeleteDialog(false)

                  if (status === 'success') {
                    setUserScheduledDeleteAt(null)
                  }
                }}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}
