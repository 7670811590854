import React, { useContext, useEffect, useState } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { CharityReviewContext } from '../../../../../contexts'
import { useParams } from 'react-router-dom'
import { fetchAttachments } from '../../../../../../services/charity.service'
import { AttachmentList } from './AttachmentList'

export const CharityAttachments = () => {
  const { charityId } = useParams() as { charityId: string }
  const { attachments, setAttachments, pendingAttachments, setPendingAttachments } = useContext(CharityReviewContext)
  const [fetchCharityAttachmentStatus, setFetchCharityAttachmentStatus] = useState<RequestStatusType>('idle')

  useEffect(() => {
    if (attachments?.length > 0) {
      return
    }

    setFetchCharityAttachmentStatus('pending')

    fetchAttachments(charityId)
      .then(({ attachments, pendingAttachments }) => {
        setAttachments(attachments)
        setPendingAttachments(pendingAttachments)
        setFetchCharityAttachmentStatus('idle')
      })
      .catch((error: any) => {
        if (error.name !== 'AbortError') {
          logError(new Error('Failed to load charity attachments'), error)
          setFetchCharityAttachmentStatus('failure')
        }
      })
  }, [charityId, setAttachments])

  if (fetchCharityAttachmentStatus === 'failure') {
    return (
      <div className="mb-lg">
        <div className="alert alert-danger">Failed to load charity attachments</div>
      </div>
    )
  }

  if (fetchCharityAttachmentStatus === 'pending') {
    return <div className="loadingSpinner" />
  }

  return (
    <div className="flex flex-col space-y-lg">
      {pendingAttachments?.length > 0 && (
        <>
          <h2>Attachments pending</h2>
          <AttachmentList charityId={charityId} attachments={pendingAttachments} />
          {/* TODO: Approve updated attachments */}
        </>
      )}

      <AttachmentList charityId={charityId} attachments={attachments} />
    </div>
  )
}
