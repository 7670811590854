import React, { FunctionComponent, useEffect, useState } from 'react'
import { AccordionPanel } from '../../../../common/accordion'
import { CampaignReview } from './CampaignReview'
import { API_HOST, authenticatedFetchData } from '../../../../../lib'
import { logError } from '@tomra/datadog-browser-logging'
import { Status } from '../../../../common/styled/Status'
import { useLocation } from 'react-router-dom'

type Props = {
  charityId: string
}

export const CharityCampaigns: FunctionComponent<Props> = ({ charityId }) => {
  const { search } = useLocation()
  const [status, setStatus] = useState<RequestStatusType>('idle')
  const [campaigns, setCampaigns] = useState<CampaignSummaryType[]>([])

  const params = new URLSearchParams(search)
  const campaignId = params.get('campaignId')

  const campaignChanged = (campaign: CharityCampaignType) => {
    setCampaigns(
      campaigns.map(c =>
        campaign.campaignId === c.id
          ? {
              ...c,
              name: campaign.name,
              startTime: campaign.startTime,
              endTime: campaign.endTime,
              status: campaign.status
            }
          : c
      )
    )
  }

  useEffect(() => {
    setStatus('pending')

    const { run, abort } = authenticatedFetchData(`${API_HOST}/charities/admin/v1/charities/${charityId}/campaign`)

    run()
      .then(response => {
        setStatus('idle')
        setCampaigns(response)
      })
      .catch((error: any) => {
        if (error.name !== 'AbortError') {
          logError(new Error('Failed to load charity campaigns'), error)
          setStatus('failure')
        }
      })

    return () => abort()
  }, [charityId])

  if (status === 'failure') {
    return (
      <div>
        <h1 className="sr-only">Campaigns</h1>
        <div className="alert alert-danger">Failed to load charity campaigns</div>
      </div>
    )
  }

  return (
    <>
      <h2 className="text-md mb-sm ml-md">Campaigns</h2>

      {status === 'pending' ? (
        <div className="loadingSpinner ml-md mt-md" />
      ) : campaigns.length === 0 ? (
        <div className="mt-md ml-md">No campaigns have been submitted by this charity yet.</div>
      ) : campaigns.length > 0 ? (
        <>
          {campaigns.map((campaign, i) => (
            <AccordionPanel
              key={`ap-${i + 1}`}
              isOpen={campaign.id === campaignId}
              title={
                <div>
                  <Status status={campaign.status} />
                  {campaign.name}
                </div>
              }
              trailingElement={
                <div className="text-right">
                  <div className="font-bold mt-xs">
                    {new Date(campaign.startTime).toLocaleDateString()} -{' '}
                    {new Date(campaign.endTime).toLocaleDateString()}
                  </div>
                </div>
              }
            >
              <CampaignReview charityId={charityId} campaignId={campaign.id} campaignChanged={campaignChanged} />
            </AccordionPanel>
          ))}
        </>
      ) : null}
    </>
  )
}
