import React, { FunctionComponent, ReactNode } from 'react'
import { SchemeAppLogo } from './SchemeAppLogo'

type Props = {
  type: 'charity' | 'campaign'
  status: 'New' | 'Changed'
  appContext: string
  label: string
  subLabel: string
  createdAt: string
  listItemAction: ReactNode
}

export const ActionListItem: FunctionComponent<Props> = ({
  status,
  type,
  appContext,
  label,
  subLabel,
  createdAt,
  listItemAction
}) => {
  return (
    <li className="px-lg py-md grid grid-cols-12 gap-x-md items-center" aria-label={`${status} ${type}: ${label}`}>
      <div className="col-span-1">
        <SchemeAppLogo appContext={appContext} />
      </div>
      <div className="col-span-2">
        <span className={`${status === 'New' ? 'tag' : 'tag tag-orange'} inline-block`}>
          {status} {type}
        </span>
      </div>
      <div className="col-span-4">
        <span>{label}</span>
        <br />
        <span className="text-sm">{subLabel}</span>
      </div>
      <div className="col-span-2">
        <div className="text-right text-sm text-storm">Created</div>
        <div className="text-right">
          {new Date(createdAt).toLocaleDateString([], {
            localeMatcher: 'best fit',
            year: '2-digit',
            month: '2-digit',
            day: '2-digit'
          })}
        </div>
      </div>
      <div className="col-span-3 flex justify-end">{listItemAction}</div>
    </li>
  )
}
