import React, { FunctionComponent } from 'react'
import { PaginatedSearch, PaginatedSearchResultItemProps } from '../../../../common/PaginatedSearch'
import { ListItem } from '../../../../common/list/List'
import { API_HOST } from '../../../../../lib'
import { usePromotedCampaigns } from './PromotedCampaignsProvider'

const CampaignSearchResultItem: FunctionComponent<PaginatedSearchResultItemProps> = ({ result, onItemClicked }) => {
  const { promoteCampaign, promoted, maxPromotedCampaigns } = usePromotedCampaigns()

  return (
    <ListItem
      key={`key-${result.id}`}
      label={
        <div className="flex space-x-sm items-center">
          <div className="flex-1 flex flex-col space-y-xs">
            <h4>{result.name}</h4>
            <div className="text-sm">{result.charitySummary.displayName}</div>
            <div>
              {new Date(result.startTime).toLocaleDateString()} - {new Date(result.endTime).toLocaleDateString()}
            </div>
          </div>

          <button
            className="btn btn-primary-dark"
            disabled={promoted.length === maxPromotedCampaigns}
            onClick={() => {
              promoteCampaign(result.id)
              onItemClicked()
            }}
          >
            Promote
          </button>
        </div>
      }
    />
  )
}

export const CampaignSearch: FunctionComponent = () => {
  const { appContext } = usePromotedCampaigns()

  const url = new URL(`${API_HOST}/charities/admin/v1/search/campaigns`)
  url.searchParams.append('appContext', appContext)
  url.searchParams.append('status', 'ACTIVE')

  return (
    <>
      <PaginatedSearch
        id="searchToPromote"
        label="Search for campaigns to promote"
        limit={5}
        searchUrl={url.toString()}
        ItemRenderer={CampaignSearchResultItem}
        searchLabelId="searchToPromote"
      />
    </>
  )
}
